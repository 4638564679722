.transparent {
  background-color: transparent !important ;
 }

.bright-red {
  color: red !important;
} 

.red {
  color: $red !important;
} 

.orange {
  color: $orange !important;
} 

.green {
  color: $green !important;
}

.myASA {
    color: $orange !important;
}

.card-body.dashboard {
  #mainCard {
    position:relative;
    height: 415px;
  }
  .card {
    height: 200px;
    position: relative;
    margin-bottom: 15px !important;
    .card-header {
      background-color: transparent !important;
      border-bottom: none;
      margin-top: 1rem;
      i {
        font-size: $h1-font-size;
        color: $red;
      }
      .sticker {
        position: absolute;
        right: 8px;
        top: 15px;
        width: 18px;
        padding: 0 1px;
        cursor: pointer;
        text-align: center;
        font-size: $font-size-sm;
        color: $orange;
        background-color: #EF7D002B;
      }
      
      .sticker:hover {
        background-color: $orange;
        color: $white;
      }

    }
    .card-body {
      background-image: url(/assets/images/dashboards/graph.png);
      background-position: top center;
      background-repeat: no-repeat;
      padding-top: 20%;

      .number {
        font-size: $h4-font-size;
        color: $black;
        font-weight: 500;
        margin-top: 2rem;
      }
      .label {
        font-size: $font-size-sm;
        color: $gray-500;
        font-weight: 100;
      }
    }
  }

  .card.main {
    height: 100% !important;
    .circle_mask {
      position:absolute;
    }
    .circle_mask.top {
        top: -3px;
        left: -4px;
    }
    .circle_mask.bottom {
        bottom: 0;
        right: 0px;
    }
    
    .card-header {
      font-size: $h4-font-size;
      line-height: $h2-font-size;
      font-weight: 500;
      text-align: center;
      margin-top: 1rem;
    }
    .card-body {
      background-image: none;
      text-align: center ;
      padding-top: 15%;
      .btn {
        display: block;
        margin-bottom: 1rem;
        width: 100%;
      }
     
    }
  }
  
}

.card-body.dashboard.user {
  #mainCard {
    height: 201px;
  }
  .card {
    .card-body {
      padding-top: 10%;
    }
  }
}

.section_title {
  position: relative;
  font-size: 30px;
  z-index: 1;
  overflow: hidden;
  color: $red;

}

.section_title:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 100%;
  height: 1px;
  content: '\a0';
  background-color: $gray-400;
  margin-left: 10px;
}

section.news {
  .title:hover {
    color: $red;
  }
  .action {
    font-size: 18px;
    font-weight: 500;
    padding: 0 3px;
  }
  .action.delete:hover {
    color: $red;
  }
  .action.edit:hover {
    color: $green;
  }
  .badge {
    font-size: 0.8rem;
    float: right;
    background-color: $red;
  }
  .badge:hover {
    background-color: $orange;
  }
}

