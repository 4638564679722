// 
// _buttons.scss
// 


.btn-primary {
  background-color: $orange !important;
  border: none!important;
}
.btn-round {
  border-radius: 30px;
}
.btn-icon-circle{
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  &.btn-icon-circle-sm{
    width: 30px;
    height: 30px;
    line-height: 1;
    padding: 0;
  }
}


.btn-icon-square{
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn-icon-square-sm{
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn-square{
  border-radius: 0;
}
.btn-skew{
  transform: skew(-15deg);
  i,
  span{
    display: inline-block;
    transform: skew(15deg);
  }
}
.accordion{
  .btn:focus{
    box-shadow: none;
  }
}
.btn.btn-outline-dashed {
  outline: 1px dashed $white !important;
  outline-offset: -5px;
  -moz-outline-radius: 25px;
}


.btn-link{
  &:active,
  &.active,
  &:focus,
  &:hover{
    text-decoration: none;
  }
}

.button-list, 
.button-items {
  margin-bottom: -8px;
  .btn{
      margin: 0 5px 8px 0;
  }
}
.btn{
box-shadow: none;  
&.focus,
&:focus{
  box-shadow: none;
} 
}

.btn-xs {
padding: 2px 10px;
font-size: 11px;
}

.btn-outline-light {
  color: $gray-600;
  background-image: none;
  background-color: transparent;
  border-color: $gray-300;
}


.btn-outline-dark {
  color: $gray-900;
&:hover{
  box-shadow: none;
  color: $gray-100;
}
}

.btn-outline-info{
&.focus,
&:focus{
  box-shadow: none;
}
}

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    background-color: rgba($value, 0.08) !important;
    color: $value !important;
    &:hover{
    background-color: $value !important;
    color: $white !important;
    }
  }

  .btn-de-#{$color} {
    background-color: transparent !important;
    color: $value !important;
    border: 1px solid $border_2;
    &:hover{
    background-color: $value !important;
    color: $white !important;
    border: 1px solid $value;
    }
  }
  .btn-de-dashed-#{$color} {
    background-color: transparent !important;
    color: $value !important;
    border: 1px dashed $border_2;
    &:hover{
    background-color: $value !important;
    color: $white !important;
    border: 1px solid $value;
    }
  }
}
.hover-light-color{
  &:hover{
    color: $light !important;
  }
}