//
// Invoice
// 



.invoice-head{
  border-bottom: 4px double $gray-400;
  .logo-lg{
    &.brand-light{
      display: $brand-light;
    }
    &.brand-dark{
      display: $brand-dark;
    }
  }
 .contact-detail{
   li{
     border-left: 2px solid $gray-400;
     i{
       font-size: 24px;
       color: $gray-500;
       margin-bottom: 5px;
     }
   }
 }
}