.card-header {
  width: 100%;  
  .actions {
    float: right;
    font-size: 1.3rem;
    color: $gray-500;
  }
  .delete:hover {
    color: $red;
  } 
  .edit:hover {
    color: $green;
  }
}

.article.card-body {
  img {
    width: 100%;
    height: auto;
  }
}

