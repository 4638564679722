//
// UI Slider
// 

.noUi-target {
  background: $gray-300;
  border-radius: 4px;
  border: 1px solid $border_2;
  box-shadow: inset 0 1px 1px $gray-100, 0 3px 6px -5px $gray-300;
}


.noUi-horizontal {
  .noUi-handle {
    width: 16px;
    height: 16px;
    right: -8px;
    top: -6px;
  }
}
.noUi-horizontal {
  height: 7px;
}

/* Handle stripes;
 */
.noUi-handle{
  border: 2px solid $border_2;
  border-radius: 50px;
  background: $primary;
  box-shadow: none;
  &:before,
  &:after {
   height: 4px;
   width: 1px;
   background: $white;
   left: 4px;
   top: 4px;
  }
} 
 .noUi-handle:after {
   left: 7px;
 }
 
.noUi-connect {
  background: shade-color($primary, 10%);
}


.noUi-vertical {
  width: 7px;
  .noUi-handle {
    width: 16px;
    height: 16px;
    right: -5.5px;
    top: -10px;
    &:before, 
    &:after {
      width: 5px;
      height: 1px;
      left: 3px;
      top: 7px;
    }
  }
  .noUi-handle:after {
    width: 5px;
    height: 1px;
    left: 3px;
    top: 4px;
  }  
}


.noUi-marker {
  position: absolute;
  background: rgba($gray-500, .2);
}
.noUi-marker-sub {
  background: rgba($gray-500, .3);
}
.noUi-marker-large {
  background: rgba($gray-500, .3);
}
.noUi-marker-horizontal{
  &.noUi-marker-large{
    height: 12px;
  }
}

.example-val:before {
  content: "Value: ";
}

.noUi-tooltip {
  display: none;
}
.noUi-active .noUi-tooltip {
  display: block;
}

.noUi-tooltip {
  border: 1px solid $border;
  background: tint-color($gray-100, 50%);
  color: $gray-700;
  padding: 2px 4px;
  font-size: 10px;
}