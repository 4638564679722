// 
// _badge.scss
// 

.badge{
    &.badge-dot{        
        &:after {
            position: absolute;
            content: "";
            right: 3px;
            top: -13px;
            transform: translateY(-50%);
            display: inline-block;
            height: 10px;
            width: 10px;
            border: 2px solid $card-bg;
            border-radius: 50%;
        }
        &.online{
            &:after{
                background-color: $success;
            }
        }
        &.offline{
            &:after{
                background-color: $gray-500;
            }
        }
    }
}

@each $color, $value in $theme-colors {
.badge{
    &.badge-soft-#{$color} {
      background-color: rgba($value, 0.15) !important;
      color: $value;
      box-shadow: 0px 0px 13px 0px rgba($value,0.05);
    }
    &.badge-outline-#{$color} {
      background-color: transparent !important;
      color: $value;
      border: 1px solid $value;
    }
  }
}

.badge-outline-light{
    color: $gray-700 !important;
}