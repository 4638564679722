// 
// _nav.scss
// 

.nav {
  &.nav-tabs{
      .nav-item.show,
      .nav-link{
        color: $gray-700;
          &:focus ,
          &.active{
              color: $primary;
              background-color: $card-bg;
              border-color: transparent transparent $primary;
          }
          &.active:hover{
              border-color: transparent transparent $primary;
          }
          &:hover {
              border-color: transparent transparent $border;
          }
      }
      .nav-item.show{
          .nav-link{
              color: $primary;
              background-color: $card-bg;
          }
      }
  }
  &.nav-pills{
      background-color: $light;
  }
}


.navbar-dark {
  .navbar-nav {
      .nav-link{
          &:hover,
          &:focus{
              color: $light;
          }
      }
  }
} 

.nav-tabs-custom{
  .nav{
    &.nav-tabs{
      border-bottom: none;
      display: inline-flex;
      .nav-item{
        border: 2px solid $gray-200;
        border-radius: 4px;
        margin-right: 5px;        
      }
      .nav-link{
        border-radius: 3px;
        padding: 10px 14px;
        &.active{
          background-color: $light;
          border-color: transparent;  
          color: $primary;  
          i{
              color: $primary;
          }      
        }
        i{
          font-size: 24px;
          vertical-align: middle;
          color: $secondary;
        }
      }
    }
  }
}




.nav{
  &.nav-pills-custom{
    &.nav-pills{
      .nav-item{
        .nav-link{
          padding: 4px 14px;
          font-size: 0.71rem;
          line-height: 2;
          border: 1px solid $gray-300;
          color: $gray-600;
          &.active{
            color: $white;
          }
        }
      }
    }
  }
}

.nav-pills .nav-item.show .nav-link, 
.nav-pills .nav-link.active {
  background: $primary;
  color: $white;
}
.eco-revene-history{
  .nav{
    .nav-item{
      .nav-link.active{
        color: $dark;
        background-color: $light;
      }
    }
  }
}
.nav-border{
  &.nav.nav-pills {
    background-color: transparent;
    border-bottom: 1px solid $border;
    line-height: 36px;   
  }
  .nav-item{
    margin-bottom: -1px;
  }
  .nav-link {
    color: $gray-700;
  }
  .nav-item.show .nav-link,
  .nav-link.active {
    background: transparent;
    color: $primary;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid $primary;
  }
}


.nav-pills .nav-item.show .nav-link, 
.nav-pills .nav-link.active {
  background: $primary;
  color: $white;
  border-radius: 0;
}