//
// Account Pages
// 

.auth-page {
  .auth-header-box {
    background-color: $red;
  }
}

.auth-bg {
  background: radial-gradient(circle at 39% 47%, rgba(107,107,107,0.08) 0%, rgba(107,107,107,0.08) 33.333%, rgba(72,72,72,0.08) 33.333%, rgba(72,72,72,0.08) 66.666%, rgba(36,36,36,0.08) 66.666%, rgba(36,36,36,0.08) 99.999%),radial-gradient(circle at 53% 74%, rgba(182,182,182,0.08) 0%, rgba(182,182,182,0.08) 33.333%, rgba(202,202,202,0.08) 33.333%, rgba(202,202,202,0.08) 66.666%, rgba(221,221,221,0.08) 66.666%, rgba(221,221,221,0.08) 99.999%),radial-gradient(circle at 14% 98%, rgba(184,184,184,0.08) 0%, rgba(184,184,184,0.08) 33.333%, rgba(96,96,96,0.08) 33.333%, rgba(96,96,96,0.08) 66.666%, rgba(7,7,7,0.08) 66.666%, rgba(7,7,7,0.08) 99.999%),linear-gradient(
45deg
, #f79f9b, #0f4069);
}

.ex-page-content {
  h1 {
    font-size: 98px;
    font-weight: 700;
    text-shadow: rgba($gray-500, 0.2) 1px 1px, 
                 rgba($gray-500, 0.2) 2px 2px, 
                 rgba($gray-500, 0.2) 3px 3px;
  }
}