// Dashboard


.crypto-report-history{
  .nav{  
    background-color: $light; 
    padding: 4px; 
    border-radius: 4px;
    &.nav-tabs{
      border: none;
    }
    .nav-item{
      .nav-link{
        margin-right: 5px;
        padding: 3px 8px;
        font-size: 12px;
        border-radius: 5px;
        color: $gray-700;
        font-weight: 500;
        border-color: transparent;
        &.active{
          color: $primary;
          background-color: $body-bg;
          border-color: transparent;
        }
        &:focus,
        &:hover{
          border-color: transparent;
        }
      }
    }
  }
}


.calculator-block {
  background-color:$light-alt;
  .calculator-body {
    padding: 16px;
    position: relative;
    padding-top: 0;
  }
  .cryptonatorwidget {
    border: none !important;
    padding: 0 !important;
    input,
    select {
      height: 40px !important;
      line-height: 40px !important;
      margin-bottom: 0;
      border: none;
      color: $gray-700;
      border-bottom: 1px solid $border;
      outline: none;
      padding-left: 10px;
      background-color: transparent;
      font-family: $font-primary !important;
    }
    div:last-child{
      display: none;
    }
    input option, 
    select option{
      background-color: $card-bg !important;
    }
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $gray-700;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $gray-700;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $gray-700;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $gray-700;
  }
}

.coin-market-nav{
  .nav-pills .nav-item.show .nav-link, 
  .nav-pills .nav-link.active{
    background: $card-bg;
    box-shadow: 0 7px 14px 0 rgba($gray-500, 0.12);
    border-radius: 0;
  }
}

.transaction-history{
  li{
    border-bottom: 1px solid $border_2;
    padding: 10px 0;
  }
  li:last-of-type{
    border: none;
    padding-bottom: 0;
  }  
}
.positive{
  color: $success !important;
}
.negative{
  color: $danger !important;
}
.crypto-dash-activity{
  height: 280px;
}


// .coin-market-h,
// .btc-volume-chart{
//   height: 250px !important;
// }
// .crypto-modal-btn{
//   &:focus{
//     outline: none;
//     box-shadow: none !important;
//   }
// }
// .trade-history{
//   height: 240px !important;
// }


// .market-cap-table{
//   td{
//     h5{
//       color: $gray-700;
//     }
//   }
// }

// .vol-btc{
//   font-size: 18px;
//   color: $gray-700;
//   font-weight: 600;
//   margin: 0px auto 30px;
// }


.ccc-widget {
  >div{    
    background: $card-bg !important;
  }
  .histoTitle{
    display: none;
  }  
  div:first-child{
    border:none !important;
  }
  div.histoRow:nth-of-type(2) {
    font-weight: 500 !important;
    color: $gray-700 !important;
    font-size: 13px !important; 
    background-color: $light;
    .histoChange{
      font-weight: 500;
    }  
  
  }
  div.histoRow{
    border-bottom-color: $table-border-color !important;
    font-size: 13px !important;
    color: $table-color !important;
    padding: $table-cell-padding-y $table-cell-padding-x;
  }
}





// // Wallet

// .wallet-bal-usd{
//   .wallet-title{
//     font-size: 16px;
//     color: $gray-700;
//   }
//   h3{
//     font-size: 28px;
//     color: $gray-700;
//     font-weight: 600;
//     margin-top: 20px;
//   }
// }


// .wallet-bal-crypto{
//   .wallet-title{
//     font-size: 16px;
//     color: $gray-700;
//   }
//   .coin-bal{
//     h3{
//       font-size: 16px;
//       color: $gray-700;
//     }
//   }
// }

// .btc-price{
//   h3{
//     font-size: 18px;
//     color: $gray-700;
//     font-weight: 600;
//   }
// }

// .crypto-wallet-activity{
//   min-height: 600px;
// }

// // Crypto News
// .wrap{
//   width: 100%;
//   white-space: nowrap;
//   overflow: hidden;
//   font-size: 0;
//   background: $bg-navbar;
//   border-radius: 3px;
//   display: flex;
//   align-items: center;
//   background-color: $light;
// }
// .jctkr-label{
//   height: 60px;
//   padding: 0 17px;
//   line-height: 60px;
//   background: rgba($primary,0.9);
//   font-weight: 600;
//   font-size: 16px;
//   color: $white;
//   cursor: default;
// }
// .jctkr-label:hover{
//   background: $blue;
//   color: $white;
// }
// [class*="js-conveyor-"] ul{
//   display: inline-block;
//   opacity: 0.5;
// }
// [class*="js-conveyor-"] ul li{
//   padding: 0 15px;
//   line-height: 35px;
//   font-size: 16px;
//   .usd-rate{
//     color: $gray-600;
//   }
// }



// // ICO Card

// .ico-card {
//   .ico-socials {
//     a {
//       i {
//         width: 28px;
//         height: 28px;
//         line-height: 28px;
//         text-align: center;
//         border-radius: 50%;
//         border: 1px solid $gray-300;
//         color: $gray-600;
//         transition: all .3s ease-out;
//       }
//       &:hover i{
//         &.fa-facebook-f{
//           color: $blue;
//           border: 1px solid;
//         }
//         &.fa-twitter{
//           color: $info;
//           border: 1px solid;
//         }
//         &.fa-medium-m{
//           color: $danger;
//           border: 1px solid;
//         }
//         &.fa-telegram{
//           color: $success;
//           border: 1px solid;
//         }
//         &.fa-btc{
//           color: $warning;
//           border: 1px solid;
//         }
//       }
//     }
//   }
// }



// // Setting

// .setting-card{
//   .setting-detail{
//     h3{
//       font-size: 16px;
//       color: $gray-700;
//       font-weight: 600;
//     }
//   }
//   .sett-card-icon{
//     width: 54px;
//     height: 54px;
//     text-align: center;
//     line-height: 54px;
//     align-self: center;
//     border-radius: 50%;
//     margin-right: 18px;
//     i{
//       font-size: 24px;
//     }
//     &.set-icon-purple{      
//     background-color: rgba($purple,0.1);
//     color: $purple;
//     }
//     &.set-icon-success{      
//     background-color: rgba($success,0.1);
//     color: $success;
//     }
//     &.set-icon-danger{      
//     background-color: rgba($danger,0.1);
//     color: $danger;
//     }
//   }
// }

